'use client';
import React, { FC } from 'react';
import { TileData, AdData } from './TeaserTile';
import { Dimensions } from 'types/ImageShapeType';
import TileWrapper from './TileWrapper';
import { LinkProps } from 'next/link';
import ViewAll from '../ViewAll/ViewAll';
import Link from 'next/link';
import PlaylistTile from './PlaylistTile';
import sty from './Teasers.module.css';
import Slider from 'components/Slider';
import { FavoriteItem } from 'models/FavoriteTypes/FavoriteType';

export interface TeasersProps {
  classes?: string;
  containerClassName?: string;
  itemClasses?: string;
  data?: TileData[];
  description?: boolean;
  imageShape: Dimensions;
  overlayText?: boolean;
  scroll?: boolean;
  shareIcons?: boolean;
  showDate?: boolean;
  downloadAndLearnMore?: boolean;
  dividers?: boolean;
  thumbnailClassName?: string;
  title?: string;
  subheadingText?: string;
  viewAllRoute?: LinkProps;
  viewAllTile?: boolean;
  showContributors?: boolean;
  showCategories?: boolean;
  resourceType?: boolean;
  viewAllTitle?: string;
  ycPoster?: boolean;
  AdditionalTile?: JSX.Element;
  headingElement?: string;
  tileHeadingElement?: string;
  adData?: AdData;
  showEllipsis?: boolean;
  deleteTile?: (item: { id: string; resourceType: string }) => void;
  arrowsAboveItems?: boolean;
  extendOffPageEdge?: boolean;
  tilePlayButtonText?: string;
  redArrowButtons?: boolean;
  playButtonsNextToImages?: boolean;
  showVideo?: boolean;
  templateName?: string;
}

const Teasers: FC<TeasersProps> = ({
  classes,
  containerClassName = '',
  data,
  description,
  imageShape,
  itemClasses = 'tile-grid-item',
  overlayText,
  scroll = true, // scroll determines if there is a slider or not.
  shareIcons,
  showDate,
  downloadAndLearnMore,
  dividers,
  thumbnailClassName,
  title,
  subheadingText,
  viewAllRoute,
  viewAllTile,
  showContributors,
  showCategories = true,
  viewAllTitle,
  ycPoster,
  AdditionalTile,
  headingElement = 'h3',
  tileHeadingElement = 'h4',
  adData,
  showEllipsis,
  deleteTile,
  arrowsAboveItems = true,
  extendOffPageEdge = false,
  tilePlayButtonText = '',
  redArrowButtons = false,
  playButtonsNextToImages = false,
  showVideo = false,
  templateName
}) => {
  if (!data) return null;

  const createTeaserTiles = () => {
    return data.map((item, i) => (
      <TileWrapper
        key={item.canonicalSlug || i}
        data={item}
        showDate={showDate}
        downloadAndLearnMore={downloadAndLearnMore}
        itemClasses={itemClasses}
        imageShape={imageShape}
        description={description}
        overlayText={overlayText}
        shareIcons={shareIcons}
        dividers={dividers}
        showContributors={showContributors}
        showCategories={showCategories}
        primaryCollType={item.primaryCollection && item.primaryCollection.title}
        thumbnailClassName={`${thumbnailClassName} ${
          showCategories ? '' : 'mb-1'
        }`}
        ycPoster={ycPoster}
        headingElement={tileHeadingElement}
        showEllipsis={showEllipsis}
        playButtonText={tilePlayButtonText}
        deleteTile={() => deleteTile && deleteTile(item as FavoriteItem)}
        playButtonNextToImage={playButtonsNextToImages}
        showVideo={showVideo}
      />
    ));
  };

  const createTeaserPlaylistTiles = () =>
    data.map((item, i) => {
      return (
        <PlaylistTile
          key={item.canonicalSlug || i}
          data={item}
          itemClasses={itemClasses}
          title={item.title}
          subtitle={item.subtitle}
          primaryVisuals={item.primaryVisuals}
          audio={item.audio}
          imageShape={imageShape}
          // headingElement={tileHeadingElement}
        />
      );
    });

  const TeaserData =
    title == 'Playlist' ? createTeaserPlaylistTiles() : createTeaserTiles();

  const HeadingElement = headingElement as keyof JSX.IntrinsicElements;

  const Heading = () => {
    if (title)
      return (
        <div className={sty.headingAndSubheading}>
          <HeadingElement className={sty.heading} config={''}>
            {title}
          </HeadingElement>
          {subheadingText && <div className="type-hefty">{subheadingText}</div>}
        </div>
      );
    else return <></>;
  };

  const ViewAllButton = () => {
    if (viewAllRoute?.href)
      return (
        <div className={sty.viewAllContainer}>
          <ViewAll
            className={`button button-primary`}
            title={viewAllTitle ? viewAllTitle : ''}
            route={viewAllRoute}
          />
        </div>
      );
    else return <></>;
  };

  if (viewAllTile && viewAllRoute && TeaserData) {
    TeaserData.push(
      <div className="tile-grid-item" key="view-all">
        <Link
          legacyBehavior
          {...viewAllRoute}
          className="tile-grid-viewall-wrapper"
        >
          <b className="tile-grid-viewall type-large">View All</b>
        </Link>
      </div>
    );
  }

  return (
    <div
      className={`${sty.teasers} ${containerClassName} ${
        redArrowButtons ? sty.redArrowButtons : ''
      } ${viewAllRoute ? '' : sty.noViewAll} ${
        extendOffPageEdge ? sty.extendOffPageEdge : ''
      }`}
    >
      {scroll && TeaserData.length ? (
        <>
          <Slider
            className={classes}
            containerMbSm={Boolean(viewAllRoute?.href)}
            items={TeaserData}
            heading={Heading()}
            headerSecondaryContent={ViewAllButton()}
            arrowsAboveItems={arrowsAboveItems}
          />
        </>
      ) : (
        <div>
          {Heading()}
          <div
            className={`${sty.noScroll} ${classes} ${sty[`${templateName}`]}`}
          >
            {TeaserData}
            {AdditionalTile}
          </div>
        </div>
      )}
    </div>
  );
};

export default Teasers;
