import React, { FC } from 'react';
import sty from './styles/Card.module.css';

interface Props {
  itemId?: string | number;
  children?: React.ReactNode;
}

const Card: FC<Props> = ({ children, itemId }) => {
  return (
    <div
      className={`${sty.card} ${sty.lg} slider-card`}
      data-item-id={itemId}
      role="button"
      tabIndex={0}
    >
      {children}
    </div>
  );
};

export default Card;
