'use client';
import React, { FunctionComponent, useState } from 'react';
import LinkNoPrefetch from 'components/LinkNoPrefetch/LinkNoPrefetch';
import {
  IconCloseModal,
  IconFacebook,
  IconCopy,
  IconReddit,
  IconMail,
  IconPinterest
} from 'components/Icon';
import sty from 'styles/modules/ShareModal.module.css';

interface Props {
  title: string;
  contentSlug: string;
  showModalBg?: boolean;
  onClose: () => void;
}

const ShareModel: FunctionComponent<Props> = ({
  title,
  contentSlug,
  showModalBg,
  onClose
}) => {
  const [text] = useState(`https://www.thecurrent.org${contentSlug}`);
  const [isCopied, setIsCopied] = useState(false);

  const fbLink = `http://www.facebook.com/sharer.php?u=https://thecurrent.org${contentSlug}&text=${title}sharer`;
  const emailLink = `mailto:?subject=${title}&body=https://thecurrent.org${contentSlug}`;
  const pinterestLink = `http://pinterest.com/pin/create/button/?url=https://thecurrent.org${contentSlug}&description=${title}`;
  const redditLink = `http://reddit.com/submit?url=https://thecurrent.org${contentSlug}&title=${title}`;

  const handleCopyTextClick = () => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  const handleCloseClick = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    onClose();
  };

  return (
    <>
      {showModalBg && (
        <div
          className={sty.modal_backdrop}
          onClick={() => {
            onClose();
          }}
        />
      )}
      <div className={sty.modal} id="modal">
        <div className={sty.innerModal}>
          <div className={sty.header}>
            <div className={sty.title}>Share this link via</div>
            <button onClick={handleCloseClick} className={sty.btn_close}>
              <IconCloseModal />
            </button>
          </div>
          <div className={sty.body}>
            <div className={sty.icons}>
              <LinkNoPrefetch href={fbLink}>
                <IconFacebook />
              </LinkNoPrefetch>
              <LinkNoPrefetch href={pinterestLink}>
                <IconPinterest />
              </LinkNoPrefetch>
              <LinkNoPrefetch href={redditLink}>
                <IconReddit />
              </LinkNoPrefetch>
              <LinkNoPrefetch href={emailLink}>
                <IconMail />
              </LinkNoPrefetch>
            </div>
            <div className={sty.modal_shareLink}>Share Link</div>
            <div className={sty.modal_input}>
              <input type="text" value={text} />
              <button
                onClick={handleCopyTextClick}
                className={`${sty.modal_copy_btn} ${sty.flex_shared_modal}`}
                // className="modal-copy-button flex-shared-modal"
              >
                <span aria-hidden>
                  <IconCopy />
                </span>
                {/* <span className="modal-copy-button-text hide-on-mobile"> */}
                <span
                  className={`${sty.modal_copy_btn_text} ${sty.hide_on_mobile}`}
                >
                  {isCopied ? 'Copied!' : 'Copy'}
                </span>
              </button>
            </div>
            {isCopied ? (
              <div className="success-msg">
                Copied to clipboard successfully
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareModel;
