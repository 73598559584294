import React from 'react';

function youtubeParser(url) {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
}

function getVideoPlayer(video, isAmp) {
  let playerEmbed;
  const uri = new URL(video.url);

  if (isAmp) {
    switch (uri.hostname) {
      case 'www.facebook.com':
        playerEmbed = (
          <div className="iframe-container">
            <amp-facebook
              data-href={video.url}
              width="303"
              height="552"
              layout="responsive"
              data-embed-as="video"
            ></amp-facebook>
          </div>
        );
        break;
      case 'player.vimeo.com':
      case 'mpr.apmcdn.org':
        playerEmbed = (
          <amp-video
            id={video.credit.name}
            width="500"
            height="360"
            autoPlay="autoplay"
            muted="muted"
            loop="loop"
          >
            <source src={video.url} type="video/mp4" />
          </amp-video>
        );
        break;
      case 'www.youtube.com':
        playerEmbed = (
          <amp-youtube
            data-videoid={youtubeParser(video.url)}
            width="500"
            height="360"
          ></amp-youtube>
        );
        break;
      default:
        console.error(playerEmbed, 'source is not a valid video url');
    }
  } else {
    switch (uri.hostname) {
      case 'www.facebook.com':
        playerEmbed = (
          <div className="iframe-container">
            <iframe
              src={`https://www.facebook.com/plugins/video.php?href=${video.url}&show_text=0`}
              scrolling="no"
              frameBorder="0"
              allowTransparency="true"
              allowFullScreen="true"
            ></iframe>
          </div>
        );
        break;
      case 'player.vimeo.com':
      case 'mpr.apmcdn.org':
        playerEmbed = (
          <div className="video-container">
            <video
              id={video.credit.name}
              autoPlay={true}
              muted={true}
              loop={true}
            >
              <source src={video.url} type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
          </div>
        );
        break;
      case 'www.youtube.com':
        playerEmbed = (
          <div className="iframe-container">
            <iframe
              src={`https://www.youtube.com/embed/${youtubeParser(video.url)}`}
              allowFullScreen={true}
            ></iframe>
          </div>
        );
        break;
      default:
        console.error(playerEmbed, 'source is not a valid video url');
    }
  }
  return playerEmbed;
}

const VideoPlayer = ({ video }) => {
  let videoPlayer = video && getVideoPlayer(video);

  return (
    <div className="content_primaryVisual">
      <figure className="figure">{video && videoPlayer}</figure>
    </div>
  );
};

export default VideoPlayer;
