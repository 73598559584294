import AudioType from 'models/Audio/AudioType';
import { parseToSeconds, secondsToHms } from './';

const getAudioDuration = (audio?: AudioType | null) => {
  if (
    !audio ||
    !Array.isArray(audio.encodings) ||
    !audio.encodings.length ||
    !audio.encodings[0].durationHms
  ) {
    return null;
  }

  const timeInSeconds = parseToSeconds(audio.encodings[0].durationHms);
  const durationTime = secondsToHms(timeInSeconds);
  return durationTime;
};

export default getAudioDuration;
