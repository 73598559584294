'use client';
import React, { FC, useState } from 'react';
import { Dimensions } from 'types/ImageShapeType';
import sty from './TileWrapper.module.css';
import AudioType from 'models/Audio/AudioType';
// import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import { TileData } from './TeaserTile';
import BtnPlayAudio from 'components/Button/BtnPlayAudio';
import ShareButtonsTeaser from '../ShareButtonsTeaser/ShareButtonsTeaser';
import { Thumbnail } from 'components/Tile';
import { getItemLink } from 'v1/utils';
import findAudioSource from 'utils/audio/findAudioSource';
import ShareModel from 'components/ShareModal/ShareModal';

import IconShare from 'components/Icon/IconShare';
import IconDelete from 'components/Icon/IconDelete';
import IconDots from 'components/Icon/IconDots';

import { FiDownload } from '@react-icons/all-files/fi/FiDownload';
import { IoEllipsisHorizontalSharp } from '@react-icons/all-files/io5/IoEllipsisHorizontalSharp';
import { IoLogoTwitter } from '@react-icons/all-files/io5/IoLogoTwitter';
import { IoLogoFacebook } from '@react-icons/all-files/io/IoLogoFacebook';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import { contributorsToSentence } from 'utils';
import LinkNoPrefetch from 'components/LinkNoPrefetch/LinkNoPrefetch';

dayjs.extend(utc);
dayjs.extend(timezone);

interface TileProps {
  data: TileDataWResults;
  imageShape: Dimensions;
  itemClasses?: string;
  description?: boolean;
  overlayText?: boolean;
  shareIcons?: boolean;
  showDate?: boolean;
  downloadAndLearnMore?: boolean;
  dividers?: boolean;
  showContributors?: boolean;
  showCategories?: boolean;
  primaryCollType?: string;
  thumbnailClassName?: string;
  ycPoster?: boolean;
  imageAriaHidden?: boolean;
  headingElement?: string;
  showEllipsis?: boolean;
  playButtonText?: string;
  playButtonNextToImage?: boolean;
  deleteTile?: () => void;
  showVideo?: boolean;
}

interface TileDataWResults extends TileData {
  id?: string;
  primaryAudio?: AudioType;
  streamAudio?: AudioType;
  audio?: AudioType[];
}

const Tile: FC<TileProps> = ({
  data,
  imageShape,
  itemClasses,
  shareIcons,
  showDate,
  downloadAndLearnMore,
  dividers,
  showContributors,
  showCategories = true,
  primaryCollType,
  thumbnailClassName,
  ycPoster,
  headingElement = 'h5',
  showEllipsis,
  playButtonText = '',
  playButtonNextToImage = false,
  deleteTile,
  showVideo
}) => {
  const route = getItemLink(data);
  const audio =
    data.primaryAudio ||
    data.streamAudio ||
    (data.audio && data.audio[0]) ||
    null;

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isOpen, setIsOpen] = useState<Record<string, boolean>>({});
  const [showModal, setShowModal] = useState(false);
  const title = data?.shortTitle || data.title;
  const audioSrc = findAudioSource(audio, false);
  const hide = drawerOpen ? 'hide' : '';
  const HeadingElement = headingElement as keyof JSX.IntrinsicElements;

  const toggle = (id: string | undefined) => {
    if (!id) {
      return;
    }
    setIsOpen({
      ...isOpen,
      [id]: !isOpen[id]
    });
  };

  return (
    <>
      <div className={itemClasses}>
        {showVideo && data.primaryVisuals?.video ? (
          <VideoPlayer video={data.primaryVisuals.video} />
        ) : (
          <LinkNoPrefetch {...route} className="teaser-link-wrapper">
            <Thumbnail
              alt={title} // Images that are links should have alt text that describes the destination
              className={`${thumbnailClassName} ${
                data.primaryCollection ? '' : 'mb-1'
              }`}
              dimension={imageShape.name}
              img={data.primaryVisuals?.thumbnail}
              key={data.title}
              // ycPoster={ycPoster}
            />
          </LinkNoPrefetch>
        )}
        <div className={`date-description ${hide}`}>
          <article className="tile-grid-item">
            <header>
              <div className={sty.titleGridHeader}>
                {showCategories && data.primaryCollection ? (
                  <LinkNoPrefetch
                    href={'/' + data.primaryCollection.canonicalSlug}
                    className="category-link"
                  >
                    {data.primaryCollection.title}
                  </LinkNoPrefetch>
                ) : (
                  <>
                    {showEllipsis && showCategories && (
                      <div className={sty.emptyPrimaryCollection}></div>
                    )}
                  </>
                )}

                {showEllipsis && (
                  <div className={sty.eclipesActions} key={data.id}>
                    <button
                      className={sty.eclipesButton}
                      title="favorite Option"
                      type="button"
                      onClick={() => toggle(data.id)}
                    >
                      <IconDots active={isOpen[data.id ?? '']} />
                    </button>
                    {isOpen[data.id ?? ''] && (
                      <div>
                        <ul className={sty.contextMenu}>
                          <li>
                            <div className={sty.svgContainer}>
                              <button
                                aria-label="Button - Share"
                                onClick={() => setShowModal(true)}
                              >
                                <IconShare />
                                Share
                              </button>
                            </div>
                          </li>
                          <li>
                            <div className={sty.svgContainer}>
                              <button
                                id={data.id}
                                aria-label="Button - Remove from list"
                                onClick={() => {
                                  deleteTile && deleteTile();
                                }}
                              >
                                <IconDelete />
                                Remove from list
                              </button>
                            </div>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {showModal && (
                <ShareModel
                  title="Ballot"
                  contentSlug={`/${
                    data.resourceType === 'event' ? 'events' : data.resourceType
                  }/${data.canonicalSlug}`}
                  showModalBg={showModal}
                  onClose={() => setShowModal(false)}
                />
              )}
              <LinkNoPrefetch {...route} className="teaser-link-wrapper">
                <HeadingElement
                  title={title}
                  config={''}
                  className={sty.heading}
                >
                  {title}
                </HeadingElement>
              </LinkNoPrefetch>
              {shareIcons && !showDate ? (
                <ShareButtonsTeaser contentUrl={`${route}`} title={title} />
              ) : (
                ''
              )}
            </header>
            {showDate && shareIcons && (
              <div className="tile-grid-date-social">
                {showDate && data.publishDate ? (
                  <time>
                    {dayjs
                      .tz(data.publishDate, 'America/Chicago')
                      .format('MMMM D, YYYY')}
                  </time>
                ) : (
                  ''
                )}
                {shareIcons && showDate ? (
                  <ShareButtonsTeaser contentUrl={`${route}`} title={title} />
                ) : (
                  ''
                )}
              </div>
            )}
            {showContributors && (
              <div className={`tile-grid-date ${sty.auhtorAndDate}`}>
                {contributorsToSentence(data.contributors ?? [])}
                {showDate && !shareIcons && (
                  <span className="tile-grid-date-item date-shrink">
                    <time>
                      {' '}
                      {data.contributors &&
                        data.contributors?.length > 0 &&
                        '• '}
                      {dayjs
                        .tz(data.publishDate, 'America/Chicago')
                        .format('MMM D, YYYY')}
                    </time>
                  </span>
                )}
              </div>
            )}
            <LinkNoPrefetch {...route} className="teaser-link-wrapper">
              <p className={sty.description}>{data.descriptionText}</p>
            </LinkNoPrefetch>
          </article>
          {audio?.encodings[0]?.httpFilePath && playButtonNextToImage && (
            <div className={sty.audioWrapper}>
              <BtnPlayAudio
                resourceType={data.resourceType}
                audio={audio}
                duration
              />{' '}
              {playButtonText}
            </div>
          )}
        </div>
        {shareIcons && (
          <nav
            className={`tile-grid-icon-container-episodes ${
              drawerOpen && 'show'
            }`}
          >
            <span>Share</span>
            <IoLogoFacebook
              className="tile-grid-share-icons"
              title="Facebook"
            />
            <IoLogoTwitter className="tile-grid-share-icons" title="Twitter" />
          </nav>
        )}
        {audio?.encodings[0]?.httpFilePath && !playButtonNextToImage && (
          <div className={sty.audioWrapper}>
            <BtnPlayAudio
              resourceType={data.resourceType}
              audio={audio}
              duration
            />{' '}
            {playButtonText}
          </div>
        )}
        {downloadAndLearnMore && audioSrc && (
          <div className={sty.audioWrapper}>
            <footer className="download-learn-more-footer">
              <a download href={audioSrc}>
                <FiDownload title="download audio" />
              </a>
              {/* <small>Learn More</small> */}
            </footer>
          </div>
        )}
        {shareIcons && (
          <IoEllipsisHorizontalSharp
            className={`tile-grid-share-icons-floating-episodes ${
              drawerOpen ? 'white' : 'blue'
            }`}
            onClick={() => setDrawerOpen(!drawerOpen)}
          />
        )}
      </div>
      {dividers && <hr className="fullHr" />}
    </>
  );
};
export default Tile;
