import React, { FC } from 'react';
import LinkNoPrefetch from 'components/LinkNoPrefetch/LinkNoPrefetch';
import { IoEllipsisHorizontalSharp } from '@react-icons/all-files/io5/IoEllipsisHorizontalSharp';
import { IoLogoTwitter } from '@react-icons/all-files/io5/IoLogoTwitter';
import { IoLogoFacebook } from '@react-icons/all-files/io/IoLogoFacebook';

interface Props {
  contentUrl: string;
  title: string;
}

const ShareButtonsTeaser: FC<Props> = ({ contentUrl, title }) => {
  const twitterLink =
    'http://twitter.com/share?url=' +
    'https://www.yourclassical.org/' +
    contentUrl +
    '&text=' +
    title;
  const fbLink =
    'http://www.facebook.com/sharer.php?u=' +
    'https://www.yourclassical.org/' +
    contentUrl +
    '&text=' +
    title +
    'sharer';
  return (
    <div className="logoContainer">
      <IoEllipsisHorizontalSharp className="logos ellipses" />
      <div className="showIcons">
        <span className="shareText">Share</span>
        <LinkNoPrefetch href={fbLink}>
          <IoLogoFacebook className="logos" title="Facebook" />
        </LinkNoPrefetch>
        <LinkNoPrefetch href={twitterLink}>
          <IoLogoTwitter className="logos" title="Twitter" />
        </LinkNoPrefetch>
      </div>
    </div>
  );
};

export default ShareButtonsTeaser;
