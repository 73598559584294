import React, { FC, useContext, useEffect, useState } from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import RightCarouselArrow from 'components/ApmIcons/RightCarouselArrow';
import LeftCarouselArrow from 'components/ApmIcons/LeftCarouselArrow';
import sty from './styles/Arrows.module.css';

interface ArrowProps {
  children: React.ReactNode;
  className?: string;
  disabled: boolean;
  onClick: VoidFunction;
}

const Arrow: FC<ArrowProps> = ({ children, className, disabled, onClick }) => {
  return (
    <button
      className={className}
      disabled={disabled}
      onClick={onClick}
      style={{
        opacity: disabled ? '0.35' : '1',
        userSelect: 'none'
      }}
      type="button"
    >
      {children}
    </button>
  );
};

export function LeftArrow() {
  const { isFirstItemVisible, scrollPrev, visibleElements, initComplete } =
    useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleElements.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleElements]);

  return (
    <Arrow
      className={`${sty.arrow} ${sty.arrow_left}`}
      disabled={disabled}
      onClick={() => scrollPrev()}
    >
      <LeftCarouselArrow />
    </Arrow>
  );
}

export function RightArrow() {
  const { isLastItemVisible, scrollNext, visibleElements } =
    useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(
    !visibleElements.length && isLastItemVisible
  );
  useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleElements]);

  return (
    <Arrow
      className={`${sty.arrow} ${sty.arrow_right}`}
      disabled={disabled}
      onClick={() => scrollNext()}
    >
      <RightCarouselArrow />
    </Arrow>
  );
}
