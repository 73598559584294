import { FC } from 'react';
import type { Dimensions, FigureProps, Image, ImageProps, Props } from './type';
import sty from './FallbackImage.module.css';

export const image: Record<Dimensions, Image> = {
  square: {
    height: 480,
    src: '/images/default-album-cover.png',
    width: 480
  },
  widescreen: {
    height: 360,
    src: '/images/default-album-cover.png',
    width: 640
  },
  default: {
    height: 360,
    src: '/images/default-album-cover.png',
    width: 640
  }
};

const Img: FC<ImageProps> = ({ className, dimensions }) => (
  <img
    alt="YourClassical"
    className={`${sty.fallback_image} ${className}`}
    height={image[dimensions].height}
    src={image[dimensions].src}
    width={image[dimensions].width}
  />
);

const Figure: FC<FigureProps> = ({
  className,
  dimensions,
  figureClassName
}) => (
  <figure className={figureClassName}>
    <Img className={className} dimensions={dimensions} />
    <figcaption>YourClassical</figcaption>
  </figure>
);

/**
 *
 * @param caption (Optional) A boolean to display the image in the figure element with a caption.
 * @param className (Optional) A string with one or more CSS class names to customize the image.
 * @param dimensions A string with one of the following values: 'square', 'widescreen', or 'default'.
 * @param figureClassName (Optional) A string with one or more CSS class names to customize the figure element.
 * @returns
 */
const FallbackImage: FC<Props> = ({
  caption,
  className = '',
  dimensions = 'default',
  figureClassName = ''
}) => {
  return caption ? (
    <Figure
      className={className}
      dimensions={dimensions}
      figureClassName={figureClassName}
    />
  ) : (
    <Img className={className} dimensions={dimensions} />
  );
};

export default FallbackImage;
