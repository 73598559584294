function secondsToHms(timeInSeconds: number) {
  timeInSeconds = Math.round(timeInSeconds);

  let formattedTime = '';
  let formattedMinutes = '';
  let formattedSeconds = '';
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor(timeInSeconds / 60 - hours * 60);
  const seconds = timeInSeconds - minutes * 60 - hours * 3600;

  if (hours !== 0) {
    formattedTime = hours + ':';

    if (minutes < 10) {
      formattedMinutes = '0' + minutes;
    } else {
      formattedMinutes = minutes.toString();
    }
  } else {
    formattedMinutes = minutes.toString();
  }

  if (seconds < 10) {
    formattedSeconds = '0' + seconds;
  } else {
    formattedSeconds = seconds.toString();
  }

  formattedTime = formattedTime + formattedMinutes + ':' + formattedSeconds;

  return formattedTime;
}

export default secondsToHms;
