'use client';
import { FC } from 'react';
import getAudioDuration from 'utils/audio/getAudioDuration';
import dynamic from 'next/dynamic';
const DynamicAudioButton = dynamic(
  () => import('components/AudioButton/AudioButton')
);
import IconPlay from 'components/Icon/IconPlay';
import IconPause from 'components/Icon/IconPause';
import type AudioType from 'models/Audio/AudioType';
import sty from './BtnPlayAudio.module.css';

interface Props {
  audio?: AudioType;
  className?: string;
  duration?: boolean;
  durationClassName?: string;
  mobileStretch?: boolean;
  playClassName?: string;
  playLabel?: string;
  outerLabel?: string;
  resourceType?: string;
}

/**
 * This is the default play button to be used through the site for consistencies purpose and to reduce maintainability.
 * It can be improved as we go.
 *
 * @param audio An audio object.
 * @param className (Optional) A string with one or more CSS class names to customize the container and its elements.
 * @param duration (Optional) A boolean to indicate whether or not to display the audio duration. Defaults to false.
 * @param durationClassName (Optional) A string with one or more CSS class names to customize the audio duration element.
 * @param mobileStretch (Optional) A boolean to indicate whether or not the audio button should extend the full width on mobile screens. Defaults to false.
 * @param playClassName (Optional) A string with one or more CSS class names to customize the play button and its child elements.
 * @param playLabel (Optional) A string to be placed next to the play icon within the button. Defaults to undefined which makes the button round.
 * @param outerLabel (Optional) A string to be placed next to the button.
 * @returns A play button and the audio duration based certain conditions and when the audio object has the necessary information.
 */
const BtnPlayAudio: FC<Props> = ({
  audio,
  className = '',
  duration,
  durationClassName = '',
  mobileStretch,
  playClassName = '',
  playLabel,
  outerLabel = '',
  resourceType
}) => {
  if (!audio) return null;

  const audioDuration = duration ? getAudioDuration(audio) : '';
  const icon = playLabel ? '' : sty.playAudio_icon;
  const stretchMobile = mobileStretch ? sty.mobile_stretch : '';

  return (
    <div className={`${sty.playAudio} ${stretchMobile} ${className} `}>
      <DynamicAudioButton
        {...audio}
        resourceType={resourceType}
        className={`btn ${icon} ${playClassName}`}
        playIcon={
          <IconPlay
            width="12"
            height="14.6"
            label={playLabel}
            color="var(--color-black)"
          />
        }
        pauseIcon={
          <IconPause
            width="15"
            height="15"
            label={playLabel}
            color="var(--color-black)"
          />
        }
      />
      {audioDuration && (
        <div className={`${sty.playAudio_duration} ${durationClassName}`}>
          {audioDuration}
        </div>
      )}

      {outerLabel ? (
        <span className={`${sty.playAudio_outerLabel}`}>{outerLabel}</span>
      ) : (
        ''
      )}
    </div>
  );
};

export default BtnPlayAudio;
