import React, { FC } from 'react';
import { Image } from '@apmg/mimas';
import { ImageMetadata } from 'models/PrimaryVisuals/PrimaryVisualType';
import FallbackImage, {
  getImageShapeDimensions
} from 'components/FallbackImage';

/**
 * Note: There's more work that can be done here the get this
 * into a more mature (battle tested) stage.
 *
 * The image `sizes` prop needs more investigation.  It seems to be inconsistent between the value
 * proived and the rendered image.
 *
 * References
 * - https://github.com/APMG/apm-mimas
 * - https://github.com/APMG/apm-mimas/blob/master/src/Image/Image.js
 */

type ImageSizes = 'md' | 'lg';
type ImageWidth = '120' | '300' | '400' | '600' | '1000' | '1400' | '2000';
type Alt = undefined | string | null;

interface Props {
  alt?: Alt;
  className?: string;
  fullHeight?: boolean;
  hover?: boolean;
  img?: ImageMetadata;
  dimension?: string;
  sizes?: ImageSizes;
  streamName?: string | null;
  width?: number | ImageWidth;
  cPoster?: boolean;
}

const getStreamSources = (imgName: string) => {
  return {
    imgPng: `../../../../images/streams/${imgName}.png?qs=b`,
    imgWebp: `../../../../images/streams/${imgName}.png?qs=b`
  };
};

const getStreamImage = (imgName: string, alt: Alt) => {
  const { imgPng, imgWebp } = getStreamSources(imgName);
  return (
    <picture>
      <source type="image/webp" srcSet={imgWebp} />
      <img src={imgPng} height="200" width="200" alt={alt || ''} />
    </picture>
  );
};

const imageSize = {
  lg: '(max-width: 450px) 400px, 600px',
  md: '400px'
};

const getRegularImage = (
  width?: number | ImageWidth,
  alt?: string | null,
  img?: ImageMetadata,
  dimension?: string,
  sizes?: ImageSizes
) => {
  if (!img) return null;
  const imgSizes = sizes && imageSize[sizes] ? imageSize[sizes] : null;
  const imgWidth = width ? `${width}px` : null;
  const size = imgWidth || imgSizes || sizes || '';

  return (
    <Image
      image={{ ...img, preferredAspectRatio: null }}
      aspectRatio={dimension}
      sizes={size}
      alt={alt}
      loading="lazy"
    />
  );
};

/**
 * A generic component for rendering an image. It can work with the primary visual collection and stream images.
 * The fallback image will be rendered if the stream image ('streamName' prop) or the primary visuall image ('img' prop) is not provided.
 * Under the hood it uses the Image component from @apmg/mimas to render (if available) the image from primaryVisuals.
 *
 * @param alt (Optional) A string for the image 'alt' attribute. If the thumbnail is a link and is immediately followed by text which links to the same
 * thing, leave this empty. If the thumbnail is a link without accompanying text, the alt text should describe the link destination. Otherwise, the alt
 * text should describe the image.
 * @param className (Optional) A string with one or more CSS class names to customize the image container and its child elements.
 * @param img (Optional) The thumbnail image from the primary visual collection.
 * @param dimension (Optional) A string to indicate the image dimension.
 * @param sizes (Optional & Experimental) A string from the ImageSizes type.  This prop will be ignore if a value is provided to the `width` prop.
 * @param streamName (Optional) The name of the stream image. If provided, the 'img' prop will be ignored.
 * @param width (Optional) A number to indicate the image width.  This apply only when the 'img' prop is provided.
 * @returns
 */
const Thumbnail: FC<Props> = ({
  alt = '',
  className = '',
  fullHeight,
  img,
  dimension,
  sizes,
  streamName,
  width,
  cPoster
}) => {
  const heightFull = fullHeight ? 'lead' : '';
  const fallBackDimensions = getImageShapeDimensions(dimension || 'default');
  const posterCurrent = cPoster ? 'c-poster' : '';

  const Image = streamName
    ? getStreamImage(streamName, alt)
    : getRegularImage(width, alt, img, dimension, sizes);

  return (
    <div
      className={`tile_thumbnail ${posterCurrent} ${heightFull} ${className}`}
    >
      {Image ? Image : <FallbackImage dimensions={fallBackDimensions} />}
    </div>
  );
};

export default Thumbnail;
