'use client';
import React, { FC, ReactNode } from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import Card from './Card';
import { LeftArrow, RightArrow } from './Arrows';
import sty from './styles/Slider.module.css';

interface Props {
  className?: string;
  containerMbSm?: boolean;
  items?: ReactNode[];
  arrowsAboveItems?: boolean;
  noNavigation?: boolean;
  noPaddingX?: boolean;
  heading?: JSX.Element;
  headerSecondaryContent?: JSX.Element;
}

const Slider: FC<Props> = ({
  className = '',
  containerMbSm,
  items,
  arrowsAboveItems = true,
  noNavigation = false,
  noPaddingX,
  heading,
  headerSecondaryContent
}) => {
  const slides = items;

  if (!slides || !slides.length) return null;

  const styArrowsAboveItems = arrowsAboveItems ? sty.arrowsAboveItems : '';
  const styNoNavigation = noNavigation ? sty.no_navigation : '';
  const styNoPaddingX = noPaddingX ? sty.no_paddingX : '';
  const styContainerPbSm = containerMbSm ? sty.container_mbSm : '';

  const Header = () => {
    return (
      <div className={`${sty.headerWithinSlider} container tablet-stretch`}>
        <div className={sty.heading}>{heading}</div>
        <div className={sty.headerArrowsContainer}>
          <div className={sty.headerArrows}>
            <LeftArrow />
            <RightArrow />
          </div>
          {headerSecondaryContent}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`tablet-stretch slider ${sty.container} ${styArrowsAboveItems} ${styContainerPbSm} ${styNoPaddingX} ${className}`}
    >
      <div className={`${sty.slider} ${styNoNavigation}`}>
        <ScrollMenu
          Header={Header()}
          LeftArrow={noNavigation ? null : LeftArrow}
          RightArrow={noNavigation ? null : RightArrow}
        >
          {slides.map((item, id) => {
            return (
              <Card itemId={id} key={id}>
                {item}
              </Card>
            );
          })}
        </ScrollMenu>
      </div>
    </div>
  );
};
export default Slider;
