import { FC } from 'react';

interface Props {
  height?: string;
  width?: string;
  color?: string;
}

const RightCarouselArrow: FC<Props> = ({
  width = '25',
  height = '24',
  color = 'var(--color-white)'
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Scroll Left"
    >
      <path
        d="M5.5 12H19.5M19.5 12L12.5 5M19.5 12L12.5 19"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RightCarouselArrow;
