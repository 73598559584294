function parseToSeconds(time: string) {
  const array = time.split(':');
  const parseToSeconds =
    parseInt(array[0], 10) * 60 * 60 +
    parseInt(array[1], 10) * 60 +
    parseInt(array[2], 10);

  return parseToSeconds;
}

export default parseToSeconds;
