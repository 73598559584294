import React, { FC } from 'react';
import Link, { LinkProps } from 'next/link';
import sty from './ViewAll.module.css';

interface Props {
  className?: string;
  route: LinkProps;
  title?: string;
  children?: React.ReactNode;
}

const ViewAll: FC<Props> = ({ className = '', route, children, title }) => {
  return (
    <Link legacyBehavior {...route}>
      <a className={`btn ${className}`}>
        {children ? (
          children
        ) : title ? (
          title
        ) : (
          <>
            View all<span aria-hidden>{' >'}</span>
          </>
        )}
      </a>
    </Link>
  );
};

export default ViewAll;
