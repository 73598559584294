import { image } from '../FallbackImage';
import { Dimensions } from '../type';

/**
 * @param shape A string or `imageShape.name` to identify whether or not the fallback image includes this image.
 *
 * @returns A string to be used on the fallback image dimensions props.  It defaults to widescreen.
 */
const getImageShapeDimensions = (shape: string) => {
  return image[shape as Dimensions] ? (shape as Dimensions) : 'default';
};

export default getImageShapeDimensions;
