import { FC } from 'react';
import Thumbnail from './Thumbnail';
import { ImageMetadata } from 'models/PrimaryVisuals/PrimaryVisualType';
import sty from './styles/tile.module.css';

interface Props {
  description?: string;
  img?: ImageMetadata;
  imgAlt?: string;
  imgDimensions?: string;
  imgClassName?: string;
  cPoster?: boolean;
  title?: string;
  url?: string;
}

const Tile: FC<Props> = ({
  description,
  img,
  imgAlt,
  imgDimensions,
  imgClassName = '',
  cPoster,
  title
}) => {
  return (
    <div className={sty.tile}>
      {img && (
        <Thumbnail
          alt={imgAlt}
          className={imgClassName}
          dimension={imgDimensions}
          img={img}
          cPoster={cPoster}
        />
      )}

      {title && <h3>{title}</h3>}
      {description && <p className={sty.description}>{description}</p>}
    </div>
  );
};

export default Tile;
