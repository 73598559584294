import React, { FC, useContext } from 'react';
import { AudioContext } from 'context/AudioContext';
import AudioType from 'models/Audio/AudioType';
import findAudioSource from 'utils/audio/findAudioSource';

interface Props extends AudioType {
  children: React.ReactNode;
  className?: string;
  label?: string;
  mobileImage?: string;
}

// Use this class to add audio functionality to the component passed in
const AudioWrapper: FC<Props> = (props) => {
  const context = useContext(AudioContext);

  const cleanedSource = findAudioSource(props);
  if (!cleanedSource) {
    return props.children ? <>{props.children}</> : null;
  }

  return (
    <span
      className={props.className}
      onClick={(e) => {
        e.preventDefault;
        context.handleAudioButtonClick &&
          context.handleAudioButtonClick(
            cleanedSource,
            props.title,
            props.label || '',
            props.programId,
            props.mobileImage || ''
          );
      }}
    >
      {props.children}
    </span>
  );
};

export default AudioWrapper;
