'use client';
import React, { FC, useState } from 'react';
import AudioType from 'models/Audio/AudioType';
import PrimaryVisualsType from 'models/PrimaryVisuals/PrimaryVisualType';
import { trimTime } from 'utils/index';
import { getItemLink } from 'v1/utils';
import { TileData } from './TeaserTile';
import { Dimensions } from 'types/ImageShapeType';
import LinkNoPrefetch from 'components/LinkNoPrefetch/LinkNoPrefetch';
import { Image } from '@apmg/mimas';
import AudioWrapper from '../AudioButton/AudioWrapper';
import { IoEllipsisHorizontalSharp } from '@react-icons/all-files/io5/IoEllipsisHorizontalSharp';
import { IoLogoTwitter } from '@react-icons/all-files/io5/IoLogoTwitter';
import { IoLogoFacebook } from '@react-icons/all-files/io/IoLogoFacebook';
import { IoMdPlay } from '@react-icons/all-files/io/IoMdPlay';

export interface PlaylistTileProps {
  title: string;
  subtitle?: string;
  audio?: AudioType[];

  primaryVisuals?: PrimaryVisualsType;
  data: TileData;
  itemClasses?: string;
  imageShape: Dimensions;
}

const PlaylistTile: FC<PlaylistTileProps> = ({
  title,
  data,
  subtitle,
  audio,
  imageShape,
  itemClasses
}) => {
  const listenTime = audio && audio[0].encodings[0].durationHms;
  const route = getItemLink(data);

  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <div
      className={`tile-grid-item hover-effect ${itemClasses} mobile-horizontal`}
    >
      <div>
        {data.audio?.length && data.audio[0].encodings[0]?.httpFilePath ? (
          <AudioWrapper {...data.audio[0]}>
            {data.primaryVisuals?.thumbnail ? (
              <div className="tile-image-container">
                <Image
                  image={data.primaryVisuals.thumbnail}
                  aspectRatio={imageShape.name}
                  sizes={`${imageShape.width}px`}
                  className="tile-grid-image"
                  alt={data.primaryVisuals.thumbnail.shortCaption}
                  loading="lazy"
                />
                <div className="hover-button">
                  <IoMdPlay className="hover-button-icon" />
                </div>
              </div>
            ) : (
              <img
                src="/yc-fallback-square.png"
                className="tile-grid-image"
                height="480"
                width="480"
                alt="The Current"
              />
            )}
          </AudioWrapper>
        ) : (
          <img
            src="/yc-fallback-square.png"
            className="tile-grid-image"
            height="480"
            width="480"
            alt="The Current"
          />
        )}
        {!drawerOpen ? (
          <>
            <article>
              <LinkNoPrefetch {...route}>
                <h5>{title}</h5>
              </LinkNoPrefetch>
              <p>{subtitle}</p>
              {listenTime ? (
                <div className="type-micro tile-grid-metadata">
                  Listen &bull; {trimTime(listenTime)}
                </div>
              ) : (
                ''
              )}
            </article>
          </>
        ) : (
          <>
            <nav className="tile-grid-icon-container">
              <span>Share</span>
              <IoLogoFacebook className="tile-grid-share-icons" />
              <IoLogoTwitter className="tile-grid-share-icons" />
            </nav>
          </>
        )}
      </div>
      <IoEllipsisHorizontalSharp
        className={`tile-grid-share-icons-floating ${
          drawerOpen ? 'white' : 'blue'
        }`}
        onClick={() => setDrawerOpen(!drawerOpen)}
      />
    </div>
  );
};

export default PlaylistTile;
